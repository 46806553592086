.glossy-button {
  display: inline-block;
  padding: 8px 16px;
  background: linear-gradient(180deg, #383838 0%, #272727 100%);
  color: inherit;
  text-decoration: none;
  border-radius: 6px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.2),
    inset 0 1px 1px rgba(255, 255, 255, 0.1);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.glossy-button:hover {
  background: linear-gradient(180deg, #404040 0%, #2d2d2d 100%);
}

.glossy-button:active {
  background: linear-gradient(180deg, #272727 0%, #383838 100%);
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.2),
    inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

a.glossy-button:visited {
  color: inherit;
}
